import React from "react";
import { connect } from 'react-redux';

import { Row, Col, Button, } from "reactstrap";
import { history } from '_helpers';
import { config } from '_constants';

class TikTokMobileRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUrl: "",
      universalLink: "",
    };
  }

  componentDidMount() {
    const search = this.props.location.search;
    const urlParams = new URLSearchParams(search);
    const code = urlParams.get("code");
    console.log("Code)", code)
    // Step 1: Check if code is present and valid
    if (code && /^[a-zA-Z0-9_-]{30,}$/.test(code)) {
  
      // Step 2: Validate the source domain
      const url = new URL(config.serverUrl);
      const expectedHost = url.host; 
      console.log("Expected host", expectedHost, window.location.hostname, window.location)
      const appUrl = `livenyte://tiktok?code=${code}`;
      const universalLink = `https://livenyte.com/mobile/redirect/tiktok/?code=${code}`;

      this.setState({ appUrl, universalLink });

      // Step 4: Open app via Universal Link
      window.location.href = appUrl;

    //   if (window.location.host === expectedHost) {
  
    //     // Step 3: Safe redirect construction
    //     const appUrl = `livenyte://stripeconnect?code=${code}`;
    //     const universalLink = `https://livenyte.com/mobile/redirect/stripeconnect/?code=${code}`;
  
    //     this.setState({ appUrl, universalLink });
  
    //     // Step 4: Open app via Universal Link
    //     window.location.href = appUrl;
  
    //     // Optional: Fallback to universal link
    //     // setTimeout(() => {
    //     //   window.location.href = universalLink;
    //     // }, 500);
  
    //   } else {
    //     // Step 5: Handle untrusted domain (Warn or redirect elsewhere)
    //     console.warn("Untrusted URL. No redirection.");
    //     // Show error message or redirect to home page
    //   }
  
    } else {
      // Step 6: Invalid code format (e.g., alert user)
      console.warn("Invalid code format.");
      // Handle invalid code scenario (e.g., show error or redirect to home page)
    }
  }

  render() {
    const { appUrl, universalLink } = this.state;

    return (
      <div className="bg-dark py-8">
        <div className="text-center py-8">
          <Row>
            <Col lg={8} xs={11} className="ml-auto mr-auto">
            <h1 className={"display-1 mb-0 livenyte-title text-primary" }>
                LiveNyte
            </h1>
              <h4 className="text-uppercase ls-1 text-white pb-3 special-underline">
                Mobile app link
              </h4>
              <h4 className="text-white pb-3">
                If this did not redirect into the LiveNyte mobile app, click the button below.
              </h4>
              <a href={universalLink}>
                <Button
                    className="mt-4 bg-gradient-livenyte title"
                    color="primary">
                  Open in mobile app
                </Button>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
    const {  fanModeData} = state;
    const { fanMode } = fanModeData;
  
    return {      
      fanMode,
    };
  }

const connectedTikTokMobileRedirect = connect(mapStateToProps)(TikTokMobileRedirect);
export { connectedTikTokMobileRedirect as TikTokMobileRedirect }; 