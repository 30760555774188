import { lazy } from 'react';
import {Redirect} from "views/Redirect/Redirect.jsx";
import {StripeMobileRedirect} from "views/Redirect/StripeMobileRedirect.jsx";
import {TikTokMobileRedirect} from "views/Redirect/TikTokMobileRedirect.jsx";

// import Alternative from "views/pages/dashboards/Alternative.jsx";
// import Buttons from "views/pages/components/Buttons.jsx";
// import {CalendarView} from "views/pages/Calendar.jsx";
// import Cards from "views/pages/components/Cards.jsx";
// import Charts from "views/pages/Charts.jsx";
// import Components from "views/pages/forms/Components.jsx";
// import Dashboard from "views/pages/dashboards/Dashboard.jsx";
// import Elements from "views/pages/forms/Elements.jsx";
// import Google from "views/pages/maps/Google.jsx";
// import Grid from "views/pages/components/Grid.jsx";
// import Icons from "views/pages/components/Icons.jsx";
// import Lock from "views/pages/auth/Lock.jsx";
// import Landing from "views/pages/auth/Landing.jsx";
// import Profile from "views/pages/auth/Profile.jsx";
// import ReactBSTables from "views/pages/tables/ReactBSTables.jsx";


// import Login from "views/pages/auth/Login.jsx";
const Login = lazy(() => import("views/pages/auth/Login.jsx"));
// import LogOutPage from "views/LogOutPage/LogOutPage.jsx";
const LogOutPage = lazy(() => import("views/LogOutPage/LogOutPage.jsx"));

// import Notifications from "views/pages/components/Notifications.jsx";
// import Pricing from "views/pages/auth/Pricing.jsx";
const Pricing = lazy(() => import("views/pages/auth/Pricing.jsx"));

// import MobilePagePricing from "views/MobilePages/MobilePagePricing.jsx";
const MobilePagePricing = lazy(() => import("views/MobilePages/MobilePagePricing.jsx"));

// import BusinessLandingPage from "views/LandingPages/BusinessLandingPage.jsx";
const BusinessLandingPage = lazy(() => import("views/LandingPages/BusinessLandingPage.jsx"));

// import CelebrityLandingPage from "views/LandingPages/CelebrityLandingPage.jsx";
const CelebrityLandingPage = lazy(() => import("views/LandingPages/CelebrityLandingPage.jsx"));

// import FanLandingPage from "views/LandingPages/FanLandingPage.jsx";
const FanLandingPage = lazy(() => import("views/LandingPages/FanLandingPage.jsx"));

// import RegisterPage from "views/pages/RegisterPage.jsx";
const RegisterPage = lazy(() => import("views/pages/RegisterPage.jsx"));

// import LockScreenPage from "views/Pages/LockScreenPage.jsx";
// import ConfirmAccountPage from "views/pages/ConfirmAccountPage.jsx";
const ConfirmAccountPage = lazy(() => import("views/pages/ConfirmAccountPage.jsx"));

// import Register from "views/pages/auth/Register.jsx";
const Register = lazy(() => import("views/pages/auth/Register.jsx"));

// import Sortable from "views/pages/tables/Sortable.jsx";
// import Tables from "views/pages/tables/Tables.jsx";
// import Typography from "views/pages/components/Typography.jsx";
// import Validation from "views/pages/forms/Validation.jsx";
// import Vector from "views/pages/maps/Vector.jsx";
// import Widgets from "views/pages/Widgets.jsx";



// import {EventsLandingPage} from "views/LandingPages/EventsLandingPage.jsx";

// import {Following} from "views/Following/Following.jsx";

// import BusinessProfile from "views/BusinessProfile/BusinessProfile.jsx";
const BusinessProfile = lazy(() => import("views/BusinessProfile/BusinessProfile.jsx"));

// import UserProfile from "views/UserProfile/UserProfile.jsx";
// import CelebProfile from "views/CelebProfile/CelebProfile.jsx";
const CelebProfile = lazy(() => import("views/CelebProfile/CelebProfile.jsx"));

// import AgencyProfile from "views/AgencyProfile/AgencyProfile.jsx";
const AgencyProfile = lazy(() => import("views/AgencyProfile/AgencyProfile.jsx"));

// import SharedCelebProfile from "views/SharedCelebProfile/SharedCelebProfile.jsx";
const SharedCelebProfile = lazy(() => import("views/SharedCelebProfile/SharedCelebProfile.jsx"));

// import SharedBusinessProfile from "views/SharedBusinessProfile/SharedBusinessProfile.jsx";
const SharedBusinessProfile = lazy(() => import("views/SharedBusinessProfile/SharedBusinessProfile.jsx"));

// import CelebrityDashboard from "views/CelebrityDashboard/CelebrityDashboard.jsx";
const CelebrityDashboard = lazy(() => import("views/CelebrityDashboard/CelebrityDashboard.jsx"));

// import {UserDashboard} from "views/UserDashboard/UserDashboard.jsx";

// import Scheduling from "views/Scheduling/Scheduling.jsx";
const Scheduling = lazy(() => import("views/Scheduling/Scheduling.jsx"));

// import {SchedulingHelpers} from "views/SchedulingHelpers/SchedulingHelpers.jsx";
// import Event from "views/Event/Event.jsx";
const Event = lazy(() => import("views/Event/Event.jsx"));

// import BusinessEvents from "views/BusinessEvents/BusinessEvents.jsx";
const BusinessEvents = lazy(() => import("views/BusinessEvents/BusinessEvents.jsx"));

// import CelebEvent from "views/CelebEvent/CelebEvent.jsx";
const CelebEvent = lazy(() => import("views/CelebEvent/CelebEvent.jsx"));

const SchRequestEvent = lazy(() => import("views/CelebEvent/SchRequestEvent.jsx"));

// import FindCelebs from "views/FindCelebs/FindCelebs.jsx";
const FindCelebs = lazy(() => import("views/FindCelebs/FindCelebs.jsx"));


// import {AvailableCelebs} from "views/AvailableCelebs/AvailableCelebs.jsx";
// import CreateUpdateEvent from "views/CreateUpdateEvent/CreateUpdateEvent.jsx";
const CreateUpdateEvent = lazy(() => import("views/CreateUpdateEvent/CreateUpdateEvent.jsx"));

// import CreateUpdateTicket from "views/CreateUpdateTicket/CreateUpdateTicket.jsx";
const CreateUpdateTicket = lazy(() => import("views/CreateUpdateTicket/CreateUpdateTicket.jsx"));

// import CreateUpdateSubevent from "views/CreateUpdateSubevent/CreateUpdateSubevent.jsx";
const CreateUpdateSubevent = lazy(() => import("views/CreateUpdateSubevent/CreateUpdateSubevent.jsx"));

// import BusinessDashboard from "views/BusinessDashboard/BusinessDashboard.jsx";
const BusinessDashboard = lazy(() => import("views/BusinessDashboard/BusinessDashboard.jsx"));

// import {UpcomingEvents} from "views/UpcomingEvents/UpcomingEvents.jsx";
// import {NearbyEventsForCeleb} from "views/NearbyEventsForCeleb/NearbyEventsForCeleb.jsx";

// import AgencyDashboard from "views/AgencyDashboard/AgencyDashboard.jsx";
const AgencyDashboard = lazy(() => import("views/AgencyDashboard/AgencyDashboard.jsx"));

// import Welcome from "views/Welcome/Welcome.jsx";
const Welcome = lazy(() => import("views/Welcome/Welcome.jsx"));

// import UserHome from "views/UserHome/UserHome.jsx";
const UserHome = lazy(() => import("views/UserHome/UserHome.jsx"));


// import {DiscoverCelebs} from "views/DiscoverCelebs/DiscoverCelebs.jsx";
// import SubscriptionProduct from "views/SubscriptionProduct/SubscriptionProduct.jsx";
const SubscriptionProduct = lazy(() => import("views/SubscriptionProduct/SubscriptionProduct.jsx"));

// import {SubscriptionProducts} from "views/SubscriptionProducts/SubscriptionProducts.jsx";
// import Marketplace from "views/Marketplace/Marketplace.jsx";
const Marketplace = lazy(() => import("views/Marketplace/Marketplace.jsx"));

// import MarketplaceProduct from "views/MarketplaceProduct/MarketplaceProduct.jsx";
const MarketplaceProduct = lazy(() => import("views/MarketplaceProduct/MarketplaceProduct.jsx"));

// import MarketplaceSearch from "views/MarketplaceSearch/MarketplaceSearch.jsx";
const MarketplaceSearch = lazy(() => import("views/MarketplaceSearch/MarketplaceSearch.jsx"));

// import {MarketplaceFeed} from "views/MarketplaceFeed/MarketplaceFeed.jsx";
// import {MarketplacePurchases} from "views/MarketplacePurchases/MarketplacePurchases.jsx";
// import NewsletterOrBlogPage from "views/NewsletterOrBlogPage/NewsletterOrBlogPage.jsx";
const NewsletterOrBlogPage = lazy(() => import("views/NewsletterOrBlogPage/NewsletterOrBlogPage.jsx"));


// import {DigitalItems} from "views/DigitalItems/DigitalItems.jsx";
// import {DigitalCollections} from "views/DigitalCollections/DigitalCollections.jsx";
// import CreateDigital from "views/CreateDigital/CreateDigital.jsx";
const CreateDigital = lazy(() => import("views/CreateDigital/CreateDigital.jsx"));

// import {SubscriptionAssets} from "views/SubscriptionAssets/SubscriptionAssets.jsx";

// import {CelebPayments} from "views/CelebPayments/CelebPayments.jsx";
// import Payments from "views/Payments/Payments.jsx";
const Payments = lazy(() => import("views/Payments/Payments.jsx"));


// import {Reviews} from "views/Reviews/Reviews.jsx";
// import {PurchasedTickets} from "views/PurchasedTickets/PurchasedTickets.jsx";
// import Purchases from "views/Purchases/Purchases.jsx";
const Purchases = lazy(() => import("views/Purchases/Purchases.jsx"));


// import FAQ from "views/FAQ/FAQ.jsx";
const FAQ = lazy(() => import("views/FAQ/FAQ.jsx"));

// import {AffiliateProgram} from "views/AffiliateProgram/AffiliateProgram.jsx";
// import {CelebBestPractices} from "views/BestPractices/CelebBestPractices.jsx";
// import { BusinessBestPractices } from "views/BestPractices/BusinessBestPractices.jsx";
// import { AgencyBestPractices } from "views/BestPractices/AgencyBestPractices.jsx";
// import {AffiliateClients} from "views/AffiliateClients/AffiliateClients.jsx"
// import {FeaturedTalent} from "views/FeaturedTalent/FeaturedTalent.jsx"
// import Featuring from "views/Featuring/Featuring.jsx"
const Featuring = lazy(() => import("views/Featuring/Featuring.jsx"));


// import {Pricing} from "views/Pricing/Pricing.jsx";
// import Settings from "views/Settings/Settings.jsx";
const Settings = lazy(() => import("views/Settings/Settings.jsx"));

// import MarketingEmails from "views/Settings/MarketingEmails.jsx";
const MarketingEmails = lazy(() => import("views/Settings/MarketingEmails.jsx"));


// import ForgotPasswordPage from "views/pages/ForgotPasswordPage.jsx";
const ForgotPasswordPage = lazy(() => import("views/pages/ForgotPasswordPage.jsx"));

// import DeleteAccountPage from "views/pages/DeleteAccountPage.jsx";
const DeleteAccountPage = lazy(() => import("views/pages/DeleteAccountPage.jsx"));

// import DeleteAccountPage from "views/pages/AccountDeletionPage.jsx";
const AccountDeletionPage = lazy(() => import("views/pages/AccountDeletionPage.jsx"));

// import PrintTicketPage from "views/pages/PrintTicketPage.jsx";
const PrintTicketPage = lazy(() => import("views/pages/PrintTicketPage.jsx"));

// import BusinessViewPrivateTicketPage from "views/pages/BusinessViewPrivateTicketPage.jsx";
const BusinessViewPrivateTicketPage = lazy(() => import("views/pages/BusinessViewPrivateTicketPage.jsx"));

// import {Website} from "views/Website/Website.jsx";
// import WebsiteHomepage from "views/Website/WebsiteHomepage.jsx";
const WebsiteHomepage = lazy(() => import("views/Website/WebsiteHomepage.jsx"));

// import WatchlistContainer from "views/Watchlist/WatchlistContainer.jsx"
const WatchlistContainer = lazy(() => import("views/Watchlist/WatchlistContainer.jsx"));

// import WatchlistContainer from "views/Watchlist/WatchlistContainer.jsx"
const Inbox = lazy(() => import("views/Inbox/Inbox.jsx"));

// import Posts from "views/Posts/Posts.jsx";
const Posts = lazy(() => import("views/Posts/Posts.jsx"));

// import PostsPage from "views/PostsPage/PostsPage.jsx";
const PostsPage = lazy(() => import("views/PostsPage/PostsPage.jsx"));

// import {DraftEditor} from "views/DraftEditor/DraftEditor.jsx"

// import AudioPlayer from "components/AudioPlayer/AudioPlayer.js"

// import Roadmap from "views/Roadmap/Roadmap.jsx";

const routes = [
  // {
  //   collapse: true,
  //   name: "Dashboards",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   views: [
  //       {
  //         path: "/dashboard",
  //         name: "Dashboard",
  //         component: Dashboard,
  //         showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //         layout: "/dash"
          
  //       },
  //       {
  //         path: "/alternative-dashboard",
  //         name: "Alternative",
  //         component: Alternative,
  //         showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //         layout: "/dash"
  //       }
  //     ]
  //   },
  // {
  //   collapse: true,
  //   name: "Examples",
  //   icon: "ni ni-ungroup text-orange",
  //   state: "examplesCollapse",
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   views: [
      {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
        showFor: [],
        accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
        layout: "/auth"
      },
      {
        path: "/pricing",
        name: "Pricing",
        component: MobilePagePricing,
        showFor: [],
        accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
        layout: "/mobilePage"
      },
  //     {
  //       path: "/login",
  //       name: "Login",
  //       component: Login,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/auth"
  //     },
      {
        path: "/register",
        name: "Register",
        component: Register,
        showFor: [],
        accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
        layout: "/auth"
      },
      // {
      //   path: "/lock",
      //   name: "Lock",
      //   component: Lock,
      //   showFor: [],
      //   layout: "/auth"
      // },
      // {
      //   path: "/landing",
      //   name: "Landing",
      //   component: Landing,
      //   showFor: [],
      //   layout: "/public"
      // },
      // {
      //   path: "/profile",
      //   name: "Profile",
      //   component: Profile,
      //   showFor: [],
      //   layout: "/public"
      // },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       component: Timeline,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       component: Profile,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       component: Buttons,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/cards",
  //       name: "Cards",
  //       component: Cards,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/grid",
  //       name: "Grid",
  //       component: Grid,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       component: Notifications,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       component: Icons,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       component: Typography,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       collapse: true,
  //       name: "Multi Level",
  //       state: "multiCollapse",
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       views: [
  //         {
  //           path: "#pablo",
  //           name: "Third level menu",
  //           showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //           component: () => {},
  //           layout: "/"
  //         },
  //         {
  //           path: "#pablo",
  //           name: "Just another link",
  //           showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //           component: () => {},
  //           layout: "/"
  //         },
  //         {
  //           path: "#pablo",
  //           name: "One last link",
  //           showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //           component: () => {},
  //           layout: "/"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   views: [
  //     {
  //       path: "/elements",
  //       name: "Elements",
  //       component: Elements,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/components",
  //       name: "Components",
  //       component: Components,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/validation",
  //       name: "Validation",
  //       component: Validation,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "ni ni-align-left-2 text-default",
  //   state: "tablesCollapse",
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   views: [
  //     {
  //       path: "/tables",
  //       name: "Tables",
  //       component: Tables,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/sortable",
  //       name: "Sortable",
  //       component: Sortable,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/react-bs-table",
  //       name: "React BS Tables",
  //       component: ReactBSTables,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       component: Google,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       component: Vector,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   layout: "/dash"
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: CalendarView,
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   layout: "/dash"
  // },
    // {
    //   path: "/charts",
    //   name: "Charts",
    //   icon: "ni ni-chart-pie-35 text-info",
    //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
    //   component: Charts,
    //   layout: "/dash"
    // },
  // { 
  //   path: "/settings", 
  //   name: "Settings",
  //   layout: "/dash",
  //   // icon: "ion-ios-cog",
  //   component: Settings,
  //   showFor: [],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       component: Google,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       component: Vector,
  //       showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       layout: "/dash"
  //     }
  //   ]
  // },
  {
    path: "/celeb-dashboard",
    name: "Home",
    icon: "ni ni-compass-04  ",
    layout:"/dash",
    component: CelebrityDashboard,
    showFor: ['Specialist'],
    accessibleBy: ['Specialist'],
  },
  {
    path: "/user-dashboard",
    name: "Today",
    // icon: "icon-livenyteGlyph-translated  ",
    icon: "ni ni-compass-04  ",
    layout: "/dash",
    component: UserHome,
    showFor: ['User', 'LoggedOut'],
    accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
  },
  // {
  //   path: "/user-dashboard",
  //   name: "Home",
  //   icon: "ni ni-compass-04  ",
  //   layout:"/dash",
  //   component: UserDashboard,
  //   showFor: ['User', 'LoggedOut'],
  //   accessibleBy: ['User', 'LoggedOut'],
  // },
 

  // {
  //   path: "/upcoming-events",
  //   name: "Upcoming Events",
  //   icon: "ni ni-building  ",
  //   layout: "/dash",
  //   component: UpcomingEvents,
  //   showFor: ['User', 'LoggedOut' ],
  //   accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],

  // },

  // {
  //   path: "/discoverCelebs",
  //   name: "Discover Celebrities",
  //   icon: "ni ni-tie-bow  ",
  //   layout: "/dash",
  //   component: DiscoverCelebs,
  //   showFor: ['User', 'LoggedOut'],
  //   accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
  // },
  {
    path: "/business-dashboard",
    name: "Home",
    icon: "ni ni-compass-04  ",
    layout:"/dash",
    component: BusinessDashboard,
    showFor: ['Business'],
    accessibleBy: ['Business'],

  },

  {
    path: "/inbox",
    name: "Inbox",
    layout: "/dash",
    icon: "fas fa-envelope",
    component: Inbox,
    showFor: ['Business', 'User', 'Specialist', 'Agency'],
    accessibleBy: ['Business', 'User', 'Specialist', 'Agency'],
  },
  // {
  //   path: "/digitalcollections",
  //   name: "Collections",
  //   icon: "icon-livenyteGlyph-translated  ",
  //   layout:"/dash",
  //   component: DigitalCollections,
  //   showFor: ['Specialist'],
  //   accessibleBy: ['Specialist'],
  // },
  {
    path: "/createDigital",
    name: "Digital",
    icon: "icon-livenyteGlyph-translated  ",
    layout:"/dash",
    component: CreateDigital,
    showFor: ['Specialist'],
    accessibleBy: ['Specialist'],
  },
  // {
  //   collapse: true,
  //   name: "Create",
  //   state: "digitalCollapse",
  //   showFor: [ 'Specialist'],
  //   icon: "icon-livenyteGlyph-translated  ",
  //   views: [
  //     {
  //       path: "/subscriptionassets",
  //       name: "Upload Content",
  //       showFor: ['Specialist'],
  //       accessibleBy: ['Specialist'],
  //       component: SubscriptionAssets,
  //       layout: "/dash"
  //     },
  //     {
  //       path: "/subscriptionproducts",
  //       name: "Subscriptions",
  //       layout: "/dash",
  //       // icon: "ui-2_favourite-28",
  //       component: SubscriptionProducts,
  //       showFor: ['Specialist'],
  //       accessibleBy: ['Specialist'],
  //     },

  //     {
  //       path: "/draftEditor",
  //       name: "Draft Editor",
  //       showFor: ['Specialist'],
  //       accessibleBy: ['Specialist'],
  //       component: DraftEditor,
  //       layout: "/dash"
  //     },
  //   ]
  // },

  {
    path: "/scheduling",
    name: "Events",
    icon: "ni ni-calendar-grid-58 ",
    layout: "/dash",
    component: Scheduling,
    showFor: ['Specialist'],
    accessibleBy: ['Specialist'],
  },
  {
    path: "/website",
    name: "Website",
    layout: "/dash",
    icon: "fas fa-globe",
    component: WebsiteHomepage,
    showFor: ['Specialist'],
    accessibleBy: ['Specialist'],
  },
  {
    path: "/businessEvents",
    name: "Events",
    icon: "ni ni-calendar-grid-58  ",
    layout: "/dash",
    component: BusinessEvents,
    showFor: ['Business'],
    accessibleBy: ['Business'],
  },
  // {
  //   path: "/celeb-getting-started",
  //   name: "Celeb Getting Started",
  //   layout: "/dash",
  //   icon: "ui-2_favourite-28",
  //   component: CelebBestPractices,
  //   showFor: ['Specialist', 'LoggedOut'],
  //   accessibleBy: ['Specialist', 'LoggedOut'],
  // },
  // {
  //   path: "/business-getting-started",
  //   name: "Business Getting Started",
  //   icon: "ui-2_favourite-28",
  //   layout: "/dash",
  //   component: BusinessBestPractices,
  //   showFor: ['Business', 'LoggedOut'],
  //   accessibleBy: ['Business', 'LoggedOut'],
  // },
  // {
  //   collapse: true,
  //   name: "Events",
  //   icon: "ni ni-calendar-grid-58  ",
  //   state: "eventsCollapse",
  //   showFor: ['Business'],
  //   accessibleBy: ['Business'],
  //   views: [

      // {
      //   path: "/scheduling",
      //   name: "Scheduling",
      //   mini: "S",
      //   layout: "/dash",
      //   // icon: "ui-1_calendar-60",
      //   component: Scheduling,
      //   showFor: ['Specialist'],
      //   accessibleBy: ['Specialist'],
      // },
      // {
      //   path: "/nearbyEventsForCeleb",
      //   name: "Openings",
      //   mini: "N",
      //   layout: "/dash",
      //   component: NearbyEventsForCeleb,
      //   showFor: ['Specialist' ],
      //   accessibleBy: ['Specialist'],
      // },
      // {
      //   path: "/sch-helpers",
      //   name: "Scheduling Helpers",
      //   mini: "SH",
      //   layout: "/dash",
      //   // icon: "business_bulb-63",
      //   component: SchedulingHelpers,
      //   showFor: ['Specialist'],
      //   accessibleBy: ['Specialist'],
      // },

  //     {
  //       path: "/createEvent",
  //       name: "Create Event",
  //       layout: "/dash",
  //       component: CreateUpdateEvent,
  //       showFor: [],
  //       accessibleBy: ['Business'],
  //     },
  //     {
  //       path: "/available-celebs",
  //       name: "Available Celebrities",
  //       layout: "/dash",
  //       component: AvailableCelebs,
  //       showFor: ['Business'],
  //       accessibleBy: ['Business'],
  //     },
  //     {
  //       path: "/reviews",
  //       name: "Reviews",
  //       layout: "/dash",
  //       component: Reviews,
  //       showFor: ['Business'],
  //       accessibleBy: ['Business'],
  //     },
  //   ]
  // },
  //  {
  //   collapse: true,
  //   name: "Promote",
  //   state: "promoteCollapse",
  //   showFor: [ 'Specialist'],
  //   accessibleBy: ['Specialist'],
  //   icon: "fas fa-star  ",
  //   views: [
      
  
  // // {
  // //   collapse: true,
  // //   name: "Website",
  // //   state: "websiteCollaps",
  // //   showFor: [ 'Specialist'],
  // //   accessibleBy: ['Specialist'],
  // //   icon: "fas fa-star  ",
  // //   views: [
  // //     {
  // //       path: "/website",
  // //       name: "Website",
  // //       layout: "/dash",
  // //       // icon: "ui-2_favourite-28",
  // //       component: Website,
  // //       showFor: ['Specialist'],
  // //       accessibleBy: ['Specialist'],
  // //     },
  // //   ]
  // // },
  //   ]
  // },
  {
    path: "/marketplaceProduct/:productId",
    name: "Marketplace Product",
    layout: "/dash",
    // icon: "ui-2_favourite-28",
    component: MarketplaceProduct,
    showFor: [],
    accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
  },
  {
     path: "/marketplaceSearch",
        name: "Marketplace Search",
        layout: "/dash",
        // icon: "ui-2_favourite-28",
        component: MarketplaceSearch,
        showFor: [],
        accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
  },
  {
    // collapse: true,
    path: "/marketplace/:productTypeId",
    name: "Marketplace",
    state: "marketplaceCollapse",
    component: Marketplace,
    layout: "/dash", 
    showFor: [],
    accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
  },
  {
    // collapse: true,
    path: "/marketplace",
    name: "Marketplace",
    state: "marketplaceCollapse",
    icon: "ni ni-shop  ",
    component: Marketplace,
    layout: "/dash", 
    showFor: ['Business', 'User', 'Agency', 'LoggedOut'],
    accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],

    // views: [
    //   {
    //     path: "/marketplace",
    //     name: "Market",
    //     layout: "/dash",
    //     // icon: "ui-2_favourite-28",
    //     component: Marketplace,
    //     showFor: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
    //     accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
    //   },

      // {
      //   path: "/marketplaceSearch",
      //   name: "Marketplace Search",
      //   layout: "/dash",
      //   // icon: "ui-2_favourite-28",
      //   component: MarketplaceSearch,
      //   showFor: [],
      //   accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
      // },
      // {
      //   path: "/marketfeed",
      //   name: "Feed",
      //   layout: "/dash",
      //   // icon: "ui-2_favourite-28",
      //   component: MarketplaceFeed,
      //   showFor: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
      //   accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],
      // },
      // {
      //   path: "/marketpurchases",
      //   name: "Purchases",
      //   layout: "/dash",
      //   // icon: "ui-2_favourite-28",
      //   component: MarketplacePurchases,
      //   showFor: ['Specialist', 'Business', 'User', 'Agency'],
      //   accessibleBy: ['Specialist', 'Business', 'User', 'Agency'],
      // },

        // {
        //   collapse: true,
        //   name: "Digital Goods",
        //   state: "digitalGoodsCollapse",
        //   showFor: ['Specialist'],
        //   views: [
        //     // {
        //     //   path: "#pablo",
        //     //   name: "Sales Manager",
        //     //   layout: "/dash",
        //     //   // icon: "ui-1_calendar-60",
        //     //   component: () => {},
        //     //   showFor: ['Specialist'],
        //     // },
        //     {
        //       path: "/digitalitems",
        //       name: "Digital Items" ,
        //       showFor: ['Specialist'],
        //       component: DigitalItems,
        //       layout: "/dash"
        //     },
        //     {
        //       path: "/digitalcollections",
        //       name: "Digital Collections" ,
        //       showFor: ['Specialist'],
        //       component: DigitalCollections,
        //       layout: "/dash"
        //     },
        //   ]
        // }
      // {
      //   path: "/services",
      //   name: "Services",
      //   mini: "SH",
      //   // icon: "business_bulb-63",
      //   component: SchedulingHelpers,
      //   showFor: ['Specialist'],
      // },
    // ]
  },

  {
    path: "/featuring",
    name: "Featuring",
    icon: "ni ni-tie-bow  ",
    layout: "/dash",
    component: Featuring,
    showFor: ['Business', 'User', 'Agency', 'LoggedOut'],
    accessibleBy: ['Business', 'User', 'Specialist', 'Agency', 'LoggedOut'],
  },
  // {
  //   path: "/featured-talent",
  //   name: "Featuring",
  //   icon: "icon-livenyteGlyph-translated  ",
  //   layout: "/dash",
  //   component: FeaturedTalent,
  //   showFor: ['User', 'LoggedOut', ],
  //   accessibleBy: ['Specialist', 'Business', 'User', 'Agency', 'LoggedOut'],

  // },
  // {
  //   path: "/postsFeed",
  //   name: "Feed",
  //   layout: "/dash",
  //   // icon: "ui-2_favourite-28",
  //   component: PostsFeed,
  //   showFor: [],
  //   accessibleBy: ['User', 'Business', 'Specialist', 'Agency', 'LoggedOut'],
  // },
  // {
  //   collapse: true,
  //   name: "Posts",
  //   state: "postsCollapse",
  //   showFor: ['User', 'Business', 'Specialist', 'Agency', 'LoggedOut'],
  //   accessibleBy: ['User', 'Business', 'Specialist', 'Agency', 'LoggedOut'],
  //   icon: "ni ni-single-copy-04  ",
  //   views: [
  //     {
  //       path: "/postsFeed",
  //       name: "Feed",
  //       layout: "/dash",
  //       // icon: "ui-2_favourite-28",
  //       component: PostsFeed,
  //       showFor: [],
  //       accessibleBy: ['User', 'Business', 'Specialist', 'Agency', 'LoggedOut'],
  //     },
  //     // {
  //     //   path: "/posts",
  //     //   name: "Posts",
  //     //   layout: "/dash",
  //     //   icon: "ni ni-single-copy-04  ",
  //     //   component: Posts,
  //     //   showFor: [ 'Specialist', 'Business'],
  //     //   accessibleBy: ['Specialist', 'Business'],
  //     // },
  //   ]
  // },

  // {
  //   path: "/expressCheckout/event/:eventId/ticket/:ticketId",
  //   name: "Business Profile",
  //   icon: "business_chart-pie-36",
  //   layout: "/dash",
  //   component: ExpressCheckout,
  //   showFor: [],
  // },
  // {
  //   path: "/expressCheckout/event/:eventId",
  //   name: "Business Profile",
  //   icon: "business_chart-pie-36",
  //   layout: "/dash",
  //   component: ExpressCheckout,
  //   showFor: [],
  // },
  // // {
  //   path: "/expressCheckout/product/:productId/tier/:tierId/plan:/planId",
  //   name: "Business Profile",
  //   icon: "business_chart-pie-36",
  //   layout: "/dash",
  //   component: ExpressCheckout,
  //   showFor: [],
  // },
  // {
  //   path: "/expressCheckout/product/:productId",
  //   name: "Business Profile",
  //   icon: "business_chart-pie-36",
  //   layout: "/dash",
  //   component: ExpressCheckout,
  //   showFor: [],
  // },

  {
    path: "/posts",
    name: "Posts",
    layout: "/dash",
    icon: "ni ni-single-copy-04  ",
    component: Posts,
    showFor: [ 'Business'],
    accessibleBy: ['Business'],
  },
  { 
    path: "/businessProfile", 
    name: "Profile",
    layout: "/dash",
    icon: "ni ni-single-02  ",
    component: BusinessProfile,
    showFor: ['Business'],
    accessibleBy: ['Business'],
    
  },
  { 
    path: "/celebProfile", 
    name: "Profile",
    layout: "/dash",
    icon: "ni ni-single-02  ",
    component: CelebProfile,
    showFor: ['Specialist'],
    accessibleBy: ['Specialist'],
  },
  { 
    path: "/watchlist", 
    name: "Watchlist",
    layout: "/dash",
    icon: "fas fa-star",
    component: WatchlistContainer,
    showFor: ['Business', 'User', 'Specialist', 'Agency'],
    accessibleBy: ['Business', 'User', 'Specialist', 'Agency'],
  },

  {
    path: "/payments",
    name: "Payments",
    layout: "/dash",
    icon: "ni ni-money-coins  ",
    component: Payments,
    showFor: ['Specialist', 'Business'],
    accessibleBy: ['Specialist', 'Business'],

  },

  // {
  //   path: "/celeb-payments",
  //   name: "Payments",
  //   layout: "/dash",
  //   icon: "ni ni-money-coins  ",
  //   component: CelebPayments,
  //   showFor: ['Specialist'],
  //   accessibleBy: ['Specialist'],

  // },

  // {
  //   path: "/business-payments",
  //   name: "Payments",
  //   layout: "/dash",
  //   icon: "ni ni-money-coins  ",
  //   component: BusinessPayments,
  //   showFor: ['Business'],
  //   accessibleBy: ['Business'],
  // },
  {
    path: "/purchases",
    name: "Purchases",
    layout: "/dash",
    icon: "fas fa-solid fa-ticket  ",
    component: Purchases,
    showFor: ['User', 'Business', 'Specialist', 'Agency'],
    accessibleBy: ['User', 'Business', 'Specialist', 'Agency'],
  },
  // {
  //   path: "/purchasedTickets",
  //   name: "Tickets",
  //   layout: "/dash",
  //   icon: "fas fa-ticket-alt  ",
  //   component: PurchasedTickets,
  //   showFor: ['User', 'Business', 'Specialist', 'Agency'],
  //   accessibleBy: ['User', 'Business', 'Specialist', 'Agency'],
  // },

  { 
    path: "/agencyProfile", 
    name: "Profile",
    layout: "/dash",
    icon: "ni ni-single-02 t ",
    component: AgencyProfile,
    showFor: ['Agency'],
    accessibleBy: ['Agency'],
  },
  // { 
  //   path: "/following", 
  //   name: "Following",
  //   layout: "/dash",
  //   icon: "fas fa-star  ",
  //   component: Following,
  //   showFor: ['Business', 'User', 'Specialist', 'Agency'],
  //   accessibleBy: ['Business', 'User', 'Specialist', 'Agency'],

  // },
  // {
  //   collapse: true,
  //   name: "Partners",
  //   state: "partnersCollapse",
  //   icon: "fas fa-handshake  ",
  //   showFor: ['Specialist', 'Business', 'Agency'],
  //   accessibleBy: ['Specialist', 'Business', 'Agency'],

  //   views: [
  //     {
  //       path: "/affiliateProgram",
  //       name: "Affiliate Program",
  //       layout: "/dash",
  //       component: AffiliateProgram,
  //       showFor: ['Specialist', 'Business', 'Agency', 'LoggedOut'],
  //       accessibleBy: ['Specialist', 'Business', 'Agency', 'LoggedOut'],

  //     },
  //     {
  //       path: "/myreferrals",
  //       name: "My Referrals",
  //       layout: "/dash",
  //       component: AffiliateClients,
  //       showFor: ['Affiliate'],
  //       accessibleBy: ['Affiliate'],
  //     },

  //   ]
  // },
  {
    collapse: true,
    name: "Account",
    state: "accountCollapse",
    icon: "ni ni-settings-gear-65  ",
    showFor: ['User', 'Specialist', 'Business', 'Agency'],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency'],

    views: [
      // { 
      //   path: "/userProfile", 
      //   name: "Profile",
      //   layout: "/dash",
      //   // icon: "ni ni-single-02  ",
      //   component: UserProfile,
      //   showFor: ['User'],
      //   accessibleBy: ['User'],
      // },
      { 
        path: "/settings", 
        name: "Settings",
        layout: "/dash",
        component: Settings,
        showFor: ['Business', 'User', 'Specialist', 'Agency'],
        accessibleBy: ['Business', 'User', 'Specialist', 'Agency'],
      },
      { 
        path: "/emailPreferences", 
        name: "Emails",
        layout: "/dash",
        component: MarketingEmails,
        showFor: ['Business', 'User', 'Specialist', 'Agency'],
        accessibleBy: ['Business', 'User', 'Specialist', 'Agency'],
      },
      // { 
      //   path: "/testAudioPlayer", 
      //   name: "AudioPlayer",
      //   layout: "/dash",
      //   component: AudioPlayer,
      //   showFor: ['Business', 'User', 'Specialist', 'Agency'],
      //   accessibleBy: ['Business', 'User', 'Specialist', 'Agency'],
      // },
      {
        path: "/logout",
        name: "Log Out",
        component: LogOutPage,
        showFor: ['Business', 'User', 'Specialist', 'Agency'],
        accessibleBy: ['Business', 'User', 'Specialist', 'Agency'],

        layout: "/auth"
      },
    ]
  },
  {
    path: "/faq",
    name: "FAQ",
    layout: "/dash",
    icon: "fas fa-question-circle  ",
    component: FAQ,
    showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],

  },
  // {
  //   path: "/roadmap",
  //   name: "Roadmap",
  //   layout: "/dash",
  //   icon: "ni ni-map-big  ",
  //   component: Roadmap,
  //   showFor: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  //   accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  // },
  {
    path: "/welcome",
    name: "Welcome",
    layout: "/dash",
    component: Welcome,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency'],

  },

  {
    path: "/:requestType/:code",
    name: "Request Recommendation",
    icon: "objects_diamond",
    component: Redirect,
    layout:'/redirect',
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/",
    name: "Default Redirect",
    icon: "objects_diamond",
    layout:'/redirect',
    component: Redirect,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/redirect/stripeconnect",
    name: "Mobile Redirect",
    icon: "objects_diamond",
    layout:'/mobile',
    component: StripeMobileRedirect,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  // {
  //   path: "/redirect/tiktok",
  //   name: "Mobile Redirect",
  //   icon: "objects_diamond",
  //   layout:'/mobile',
  //   component: TikTokMobileRedirect,
  //   showFor: [],
  //   accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  // },
  {
    path: "/redirect/:requestType/:code",
    name: "Mobile Redirect",
    icon: "objects_diamond",
    layout:'/mobile',
    component: Redirect,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
 
  {
    path: "/event/:eventId",
    name: "Event",
    layout:"/dash",
    icon: "objects_diamond",
    component: Event,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/asset/:assetId",
    name: "Newsletter Or Blog Page",
    layout:"/dash",
    icon: "objects_diamond",
    component: NewsletterOrBlogPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/celeb-event",
    name: "Event",
    layout: "/dash",
    icon: "objects_diamond",
    component: CelebEvent,
    showFor: [],
    accessibleBy: ['Specialist'],
  },
  {
    path: "/sch-request/:eventId/:requestId",
    name: "Schedule Request",
    layout:"/dash",
    icon: "objects_diamond",
    component: SchRequestEvent,
    showFor: [],
    accessibleBy: ['Specialist'],
  },
  {
    path: "/findCelebsForEvent",
    name: "Find Talent",
    icon: "objects_diamond",
    layout: "/dash",
    component: FindCelebs,
    showFor: [],
    accessibleBy: [ 'Business'],

  },
    {
      path: "/createEvent",
      name: "Create Event",
      layout: "/dash",
      component: CreateUpdateEvent,
      showFor: [],
      accessibleBy: ['Business'],
    },
  {
    path: "/updateEvent",
    name: "Update Event",
    icon: "ui-1_simple-add",
    layout: "/dash",
    component: CreateUpdateEvent,
    showFor: [],
    accessibleBy: [ 'Business'],

  },
  {
    path: "/:id/postsPage/:postId",
    name: "Posts Page",
    icon: "objects_diamond",
    layout: "/dash",
    component: PostsPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/subscriptionproduct/:productId",
    name: "Subscription",
    icon: "objects_diamond",
    layout: "/dash",
    component: SubscriptionProduct,
    showFor: [],
    accessibleBy: ['Specialist'],
  },
  {
    path: "/create-update-ticket",
    name: "Ticket",
    icon: "objects_diamond",
    layout: "/dash",
    component: CreateUpdateTicket,
    showFor: [],
    accessibleBy: ['Business'],
  },
  {
    path: "/create-update-subevent",
    name: "Subevent",
    icon: "objects_diamond",
    layout: "/dash",
    component: CreateUpdateSubevent,
    showFor: [],
    accessibleBy: ['Business'],
  },
  {
    path: "/c_profile/:id",
    name: "Celebrity Profile",
    icon: "business_chart-pie-36",
    layout: "/dash",
    component: SharedCelebProfile,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/b_profile/:id",
    name: "Business Profile",
    icon: "business_chart-pie-36",
    layout: "/dash",
    component: SharedBusinessProfile,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/agency-dashboard",
    name: "Home",
    icon: "ni ni-compass-04  ",
    layout: "/dash",
    component: AgencyDashboard,
    showFor: ['Agency'],
    accessibleBy: ['Agency'],
  },


  {
    path: "/login",
    name: "Login",
    component: Login,
    showFor: ['LoggedOut'],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
    icon: "ni ni-lock-circle-open  ",
    layout: "/auth"
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogOutPage,
    showFor: [],
    layout: "/auth",
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/register-page",
    name: "Register Page",
    layout: "/auth",
    component: RegisterPage,
    showFor: [],
    accessibleBy: ['LoggedOut'],
  },
  {
    path: "/confirm-account-page",
    name: "Confirm Account Page",
    layout: "/auth",
    component: ConfirmAccountPage,
    showFor: [],
    accessibleBy: ['LoggedOut'],
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    layout: "/auth",
    component: ForgotPasswordPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/accountDeletion",
    name: "Account Deletion",
    layout: "/auth",
    component: AccountDeletionPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/delete-account",
    name: "Delete Account",
    layout: "/auth",
    component: DeleteAccountPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/print-ticket-page/:purchaseId",
    name: "Print Tickets",
    layout: "/dash",
    component: PrintTicketPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency'],
  },
  {
    path: "/printableTickets",
    name: "Print Tickets",
    layout: "/dash",
    component: BusinessViewPrivateTicketPage,
    showFor: [],
    accessibleBy: ['Business'],
  },
  {
    path: "/businessLanding",
    name: "Business Landing",
    layout: "/auth",
    component: BusinessLandingPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],

  },
  {
    path: "/creatorLanding",
    name: "Celebrity Landing",
    layout: "/auth",
    component: CelebrityLandingPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  {
    path: "/fanLanding",
    name: "Fan Landing",
    layout: "/auth",
    component: FanLandingPage,
    showFor: [],
    accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  },
  // {
  //   path: "/eventsLanding",
  //   name: "Events Landing",
  //   layout: "/auth",
  //   component: EventsLandingPage,
  //   showFor: [],
  //   accessibleBy: ['User', 'Specialist', 'Business', 'Agency', 'LoggedOut'],
  // },
  
  //////
];

export default routes;
